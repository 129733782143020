.container-meaningfulwork {
    margin: 2rem auto;
    padding: 2rem;

    max-width: calc(1000px + 12rem);
}

.container-meaningfulwork .container-meaningfulworkheader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* align-items: baseline; */
    margin-bottom: 1rem;
}

.container-meaningfulwork .container-meaningfulworkheader .logo {
    width: 70%;
}

.container-meaningfulwork .container-header-title {
  margin-top: auto;
  margin-bottom: 0.5rem;
}

.container-meaningfulwork .container-header-title .title-name {
  font-size: 28px;
  font-weight: bold;
  color: #758152;
}

.container-meaningfulwork .container-header-title .date {
  font-size: 14px;
  color: #385723;
}

.container-meaningfulwork .container-meaningfulworkcontent {
    display: grid;
    grid-template-columns: 5fr 1fr 2fr;
    grid-gap: 0px;
}

.container-meaningfulwork .container-meaningfulworkcontent .container-meaningfulworkcontentleft {
    display: grid;
    grid-template-columns: 1fr 3fr;
    /* grid-template-rows: 2fr 1fr 2fr 2fr; */
    grid-gap: 0px 1rem;
    grid-auto-flow: row;
}

.container-meaningfulwork .container-meaningfulworkcontent .container-meaningfulworkcontentright {
    margin: 0;
}

.container-meaningfulwork .container-meaningfulworkcontent .container-meaningfulworkcontentarrows {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.container-meaningfulwork .container-meaningfulworkcontent .container-meaningfulworkcontentbottom {
    display: flex;
    flex-flow: row;
    grid-column: 1 / 4;

    margin-top: 0.5rem;
}

.container-meaningfulwork .container-meaningfulworkcontent .header {
    font-size: 20px;
    font-weight: bold;
    color: #990000;
}

.container-meaningfulwork .container-meaningfulworkcontent .subheader {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #7f7f7f;
}

.container-meaningfulwork .container-meaningfulworkcontent .mb-colour {
    color: #27495d;
}

.container-meaningfulwork .container-meaningfulworkcontent .contain {
    box-shadow: 0px 1px 2px rgb(0, 0, 0, 25%);
    margin: 3px;
    padding: 3px;
}

.container-meaningfulwork .container-meaningfulworkcontent .editable {
    /* resize: none; */
    width: 100%;
    border: none;
    resize: vertical;
}

.container-meaningfulwork .container-meaningfulworkcontent .nonresizable {
    resize: none;
}

.container-meaningfulwork .container-meaningfulworkcontent .value {
    font-weight: bold;
    font-size: 18px;
    width: 99%;
}

.container-meaningfulwork .container-meaningfulworkcontent .acc-value {
    font-weight: bold;
    font-size: 14px;
    width: 99%;
}

.container-meaningfulwork .container-meaningfulworkcontent .description {
    font-size: 12px;
    width: 99%;
}

.container-meaningfulwork .container-meaningfulworkcontentbottom .mb-textarea {
    -moz-text-align-last: left; /* Firefox 12+ */
    text-align-last: left;
}

.container-meaningfulwork .container-meaningfulworkcontent .label {
    font-size: 16px;
    width: 99%;
}

.container-meaningfulwork .container-meaningfulworkcontent .container-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    padding: 0 0 0.25rem 0;
}

.container-meaningfulwork .container-meaningfulworkcontent .arrow-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);

    font-size: 12px;
    font-style: italic;
    color: #868686;
}

.container-meaningfulwork .container-meaningfulworkcontent .center-arrow {
    position: absolute;
    top: 100px;
    left: 56%;
    transform: translate(-50%, 0%);
}

.container-meaningfulwork .container-meaningfulworkcontent .left-arrow {
    position: absolute;
    top: 105px;
    left: 5%;
    transform: translate(-50%, 0%);
}

.container-meaningfulwork .container-meaningfulworkcontent .top-arrow {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container-meaningfulwork textarea {
    white-space: normal;
    text-align: center;
    -moz-text-align-last: center; /* Firefox 12+ */
    text-align-last: center;
}

.container-meaningfulwork .container-printfooter {
    margin-top: 10px;
    font-size: 6px;
}